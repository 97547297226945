<template>
  <button
    class="group p-4 mx-3 mb-6 rounded-xl border border-transparent hover:bg-blue-50 hover:border-blue-300 token-card"
    @click="redirectToDetails"
  >
    <div class="flex flex-col">
      <div class="text-center">
        <div
          class="block w-full aspect-w-10 aspect-h-10 rounded-xl bg-gray-100 overflow-hidden"
        >
          <Loader
            v-if="!token.asset_medium || !isImgLoaded"
            :classes="
              'object-cover rounded-lg w-1/3 h-1/3 absolute top-1/3 left-1/3'
            "
          />

          <img
            :src="token.asset_medium"
            width="320"
            height="320"
            class="object-cover pointer-events-none "
            :class="isImgLoaded ? 'visible' : 'invisible'"
            :alt="token.name"
            @load="onLoad"
          />
        </div>
        <h3 class="py-2 mb-2 text-24 leading-8 font-semibold truncate">
          {{ token.name }}
        </h3>

        <p v-if="showStatus" class="py-1 mb-4">
          {{ statusDisplay }}
        </p>

        <dl
          class="mb-4 grid grid-cols-2 rounded-lg overflow-hidden divide-indigo-200 divide-y-0 divide-x"
        >
          <div class="pr-2 text-right flex flex-col justify-between">
            <dt class="text-14 leading-5 font-medium text-gray-500 mb-2">
              Price
            </dt>
            <dd class="text-18 leading-7 font-bold my-auto break-words">
              {{ priceDisplay }}
            </dd>
          </div>
          <div class="px-1 flex flex-col justify-between pl-2 text-left">
            <dt class="text-14 leading-5 font-medium text-gray-500 mb-2">
              {{ bidLabel }}
            </dt>
            <dd class="text-18 leading-7 font-normal mb-auto break-words">
              {{ highestBidDisplay }}
            </dd>
          </div>
        </dl>

        <button
          class="w-1/2 py-2 rounded-lg bg-white border border-blue-300 text-gray-700 text-12 leading-4 font-medium group-hover:bg-blue-450 group-hover:border-transparent group-hover:text-gray-50"
        >
          {{ buttonLabel }}
        </button>
      </div>
    </div>
  </button>
</template>
<script>
import { formatCrypto } from '@/utils/decimal';
import Loader from '@/components/Loader';
import redirect from '@/utils/redirect';

export default {
  name: 'TokenListCard',
  components: {
    Loader,
  },
  props: {
    token: {
      type: Object,
      required: true,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isImgLoaded: false,
    };
  },
  computed: {
    highestBidDisplay() {
      return this.token.highest_bid
        ? formatCrypto(this.token.highest_bid, this.token.currency)
        : '0';
    },
    priceDisplay() {
      return formatCrypto(this.token.price, this.token.currency);
    },
    statusDisplay() {
      if (this.token.state === 'SOLD') {
        return 'Bought';
      }
      if (this.token.state === 'CREATED') {
        return 'Draft';
      }
      return 'On the market';
    },
    bidLabel() {
      return 'Highest Bid';
    },
    buttonLabel() {
      return this.token.is_owned_by_me ? 'Show NFT' : 'Make offer';
    },
  },

  methods: {
    redirectToDetails() {
      redirect({
        name: 'TokenDetails',
        params: {
          id: this.token.id,
        },
      });
    },
    onLoad() {
      this.isImgLoaded = true;
    },
  },
};
</script>
<style scoped>
.token-card {
  width: 16rem;
  min-width: 16rem;
}
</style>

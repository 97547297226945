<template>
  <div
    class="bg-gray-800 header flex flex-col space-y-8 py-8 lg:flex-row lg:space-y-0 justify-evenly items-center"
  >
    <div class="flex flex-col lg:w-1/3 md:w-1/2 w-5/6">
      <div
        class="text-gray-500 text-base leading-6 font-semibold tracking-wider uppercase mb-2"
      >
        collection
      </div>
      <div
        class="text-white text-48 leading-none font-extrabold tracking-tight mb-8 break-words"
      >
        {{ collection.name }}
      </div>
      <div class="price text-gray-100 text-16 break-words">
        {{ collection.description }}
      </div>
    </div>
    <div v-if="collection.cover_medium" class="sm:w-96 w-1/2">
      <TopImage :image="collection.cover_medium" />
    </div>
    <div v-else></div>
  </div>
</template>
<script>
import TopImage from '@/components/TopImage';

export default {
  name: 'FeaturedItem',
  components: {
    TopImage,
  },
  props: {
    collection: {
      type: Object,
      required: true,
    },
  },
  methods: {},
};
</script>
<style scoped>
.header {
  min-height: 35rem;
}
.price {
  height: fit-content;
}
</style>

<template>
  <div>
    <div>
      <FeaturedItem :collection="collection" />
    </div>
    <div
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="80"
      class="sm:ml-6 ml-3 mt-6 sm:pl-6 pl-3 py-6"
    >
      <TokenCreateModal
        v-if="isOpenCreateTokenModal"
        :initial-collection-name="collection.name"
        @close="closeCreateTokenModal"
      />

      <CollectionUpdateModal
        v-if="isOpenUpdateModal"
        :collection="collection"
        @close="closeUpdateModal"
      />

      <div class="flex flex-col mb-8 pb-6 pl-3 sm:pl-10 pr-6 sm:pr-20">
        <div
          v-if="collection.is_owned_by_me"
          class="flex justify-between items-center mb-8"
        >
          <div>
            <t-filter-select
              class="w-45 inline-block"
              placeholder="All collection NFTs"
              :options="[
                { value: 'CREATED', label: 'Drafts' },
                { value: 'FOR_SALE', label: 'On the market' },
                { value: 'SOLD', label: 'Sold' },
              ]"
              @change="filterTokensByState"
            />
            <button
              class="uppercase border-b-2 border-transparent hover:border-gray-300 hover:text-gray-600 inline-block ml-3.5 sm:ml-12"
              @click="resetFilters"
            >
              reset
            </button>
          </div>
          <div class="mb-6 grid gap-2 grid-cols-2 grid-rows-1">
            <t-button @click="openCreateTokenModal">
              Create token
            </t-button>

            <t-light-button @click="openUpdateModal">
              Edit
            </t-light-button>
          </div>
        </div>

        <AssetTitle :title="headerDisplay" />

        <Loader v-if="isLoading" :classes="'w-24 h-24 mx-auto'" />

        <div
          v-else-if="tokens.length === 0 && filters.state"
          class="text-24 text-center pt-8"
        >
          You have no NFTs that matches criteria!
        </div>

        <div v-else-if="tokens.length === 0" class="text-center text-20">
          There are no NFTs in this collection
        </div>

        <div class="flex flex-wrap justify-center">
          <TokenListCard
            v-for="token in tokens"
            :key="token.id"
            :token="token"
            :show-status="collection.is_owned_by_me"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AssetTitle from '@/components/AssetTitle';
import FeaturedItem from '@/components/FeaturedItem';
import * as api from '@/api';
import TokenListCard from '@/components/cards/TokenListCard';
import Loader from '@/components/Loader';
import TokenCreateModal from '@/components/modals/TokenCreateModal';
import CollectionUpdateModal from '@/components/modals/CollectionUpdateModal';

export default {
  name: 'CollectionDetails',
  components: {
    TokenListCard,
    AssetTitle,
    FeaturedItem,
    Loader,
    TokenCreateModal,
    CollectionUpdateModal,
  },
  data() {
    return {
      tokens: [],
      busy: false,
      lastPage: 0,
      hasMore: true,
      isLoading: true,
      collection: {},
      isOpenUpdateModal: false,
      isOpenCreateTokenModal: false,
      filters: {
        state: '',
      },
    };
  },
  computed: {
    collectionName() {
      return this.$route.params.collection;
    },
    headerDisplay() {
      return this.collection.is_owned_by_me ? 'Collection NFTs' : 'For Sale';
    },
  },
  watch: {
    async collectionName() {
      await this.fetchCollections();
      this.hasMore = true;
      this.lastPage = 0;
      this.tokens = [];
      await this.loadMore();
    },
  },
  async mounted() {
    await this.fetchCollections();
    await this.loadMore();
  },
  methods: {
    async loadMore() {
      // pagination
      if (this.busy || !this.hasMore) return;
      this.busy = true;
      this.isLoading = true;

      const { count, results } = await api.get('tokens', {
        state: this.collection.is_owned_by_me ? this.filters.state : 'FOR_SALE',
        page: this.lastPage + 1,
        collection: this.collectionName,
      });

      this.tokens = this.tokens.concat(results);
      this.hasMore = count > this.tokens.length;
      this.isLoading = false;

      this.busy = false;
      this.lastPage++;
    },

    async fetchCollections() {
      const results = await api.get('collections');
      this.collection = results.filter(
        collection => collection.name === this.collectionName,
      )[0];
    },

    cleanTokens() {
      this.tokens = [];
      this.busy = false;
      this.lastPage = 0;
      this.hasMore = true;
      this.isLoading = false;
    },

    resetFilters() {
      this.filters.state = '';
      this.cleanTokens();
      this.loadMore();
    },

    filterTokensByState(event) {
      const value = event.target?.value;
      if (value || value === '') {
        this.filters.state = value;
        this.cleanTokens();
        this.loadMore();
      }
    },

    openUpdateModal() {
      this.isOpenUpdateModal = true;
    },
    closeUpdateModal() {
      this.isOpenUpdateModal = false;
    },

    openCreateTokenModal() {
      this.isOpenCreateTokenModal = true;
    },
    closeCreateTokenModal() {
      this.isOpenCreateTokenModal = false;
    },
  },
};
</script>
<style scoped>
.asset {
  flex: 0 0 24%;
}
</style>

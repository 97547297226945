<template>
  <ModalWrapper @close="onClose">
    <div
      class="inline-block align-bottom bg-gray-0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full relative"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <button class="absolute right-5 top-5" @click="onClose">
        <img src="@/assets/icons/x.svg" alt="close" class="w-8 h-8" />
      </button>
      <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="flex items-center text-24">
          {{ headerDisplay }}
        </div>
      </div>
      <div class="w-full border-b border-blue-450"></div>
      <div
        class="px-4 pb-4 sm:p-6 sm:pb-4 text-center flex flex-col items-center"
      >
        <form class="text-18 px-4 pb-4 sm:p-6 sm:pb-4 space-y-4">
          <div class="text-center">
            <label for="name">
              Name
            </label>
            <t-input id="name" ref="name" v-model="name" />
          </div>
          <div class="text-center">
            <label for="description">
              Description
            </label>
            <t-textarea id="description" v-model="description" />
          </div>
          <div v-if="errorMessage" class="text-center">
            <p class="text-red-500">{{ errorMessage }}</p>
          </div>
          <div class="inline-block pt-6">
            <t-button
              :disabled="!name || !description || isLoading"
              class="inline-block"
              @click.prevent="updateCollection"
            >
              {{ submitLabel }}
            </t-button>
            <t-danger-button
              :disabled="!collection.is_empty"
              class="inline-block ml-2"
              @click.prevent="removeCollection"
            >
              Remove
            </t-danger-button>
          </div>
        </form>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import * as api from '@/api';
import redirect from '@/utils/redirect';
import ModalWrapper from '@/components/modals/ModalWrapper';

export default {
  name: 'CollectionUpdateModal',
  components: {
    ModalWrapper,
  },
  props: {
    collection: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // form
      name: '',
      description: '',

      // helpers
      errorMessage: '',
      isLoading: false,
    };
  },
  computed: {
    submitLabel() {
      return this.isLoading ? 'Saving...' : 'Save';
    },
    headerDisplay() {
      if (!this.collection) {
        return 'Edit collection';
      }
      return `Edit ${this.collection.name}`;
    },
  },
  async mounted() {
    this.$refs.name.focus();
    this.name = this.collection.name;
    this.description = this.collection.description;
  },
  methods: {
    async updateCollection() {
      this.isLoading = true;
      try {
        const { name } = await api.patch(`collections/${this.collection.id}`, {
          name: this.name,
          description: this.description,
        });
        if (name !== this.collection.name) {
          this.redirectToCollecionDetails(name);
        }
        this.onClose();
      } catch (e) {
        console.error(e);
        this.errorMessage = "Couldn't update collection. Try again later.";
      }
      this.isLoading = false;
    },
    async removeCollection() {
      this.isLoading = true;
      try {
        await api.remove(`collections/${this.collection.id}`);
        this.onClose();
        this.redirectToHome();
      } catch (e) {
        console.error(e);
        this.errorMessage = "Couldn't remove collection. Try again later.";
      }
      this.isLoading = false;
    },
    redirectToHome() {
      redirect({ name: 'Home' });
    },
    redirectToCollecionDetails(name) {
      redirect({
        name: 'CollectionDetails',
        params: {
          collection: name,
        },
        force: true,
      });
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

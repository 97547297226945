<template>
  <ModalWrapper @close="onClose">
    <div
      class="inline-block align-bottom bg-gray-0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full relative"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <button class="absolute right-5 top-5" @click="onClose">
        <img src="@/assets/icons/x.svg" alt="close" class="w-8 h-8" />
      </button>
      <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="flex items-center text-24">
          Create new NFT token
        </div>
      </div>
      <div class="w-full border-b border-blue-450"></div>
      <div
        class="px-4 pb-4 sm:p-6 sm:pb-4 text-center flex flex-col items-center"
      >
        <form class="text-18 px-4 pb-4 sm:p-6 sm:pb-4 space-y-4">
          <div class="text-center">
            <label for="name">
              Name
            </label>
            <t-input id="name" ref="name" v-model="name" />
          </div>
          <div class="text-center">
            <label for="collection">
              Collection
            </label>
            <t-select
              id="collection"
              ref="collection"
              v-model="collection"
              :options="collectonOptions"
            />
            <p class="text-left text-12 mt-1">
              Cannot see appropriate collecton? You should create it first.
            </p>
          </div>
          <div class="text-center">
            <label for="description">
              Description
            </label>
            <t-textarea id="description" v-model="description" />
          </div>
          <div class="text-center">
            <t-file-input
              id="file"
              ref="file"
              v-model="filePath"
              @change="fileSelected"
            />
          </div>
          <div v-if="errorMessage" class="text-center">
            <p class="text-red-500">{{ errorMessage }}</p>
          </div>
          <div class="max-w-max mx-auto">
            <t-button
              :disabled="
                !name || !collection || !description || !file || isLoading
              "
              @click.prevent="createToken"
            >
              {{ submitLabel }}
            </t-button>
          </div>
        </form>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import * as api from '@/api';
import redirect from '@/utils/redirect';

import ModalWrapper from '@/components/modals/ModalWrapper';

export default {
  name: 'TokenCreateModal',
  components: {
    ModalWrapper,
  },
  props: {
    initialCollectionName: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      // form
      name: '',
      description: '',
      collection: '',
      tags: [],
      filePath: null,
      file: null,

      // helpers
      collectonOptions: [{ value: '', label: '---' }],
      errorMessage: '',
      isLoading: false,
    };
  },
  computed: {
    submitLabel() {
      return this.isLoading ? 'Creating...' : 'Create';
    },
  },
  async mounted() {
    this.$refs.name.focus();
    this.collection = this.initialCollectionName;
    await this.fetchCollections();
  },
  methods: {
    fileSelected() {
      const files = this.$refs.file.$refs.input.files;
      if (files.length) {
        this.file = files[0];
      }
    },

    async fetchCollections() {
      const options = await api
        .get('collections')
        .then(data => data.map(d => ({ value: d.name, label: d.name })));

      if (options.length) {
        this.collectonOptions = [{ value: '', label: '---' }, ...options];
        if (!this.collection) {
          this.collection = options[0].value;
        }
      }
    },

    async createToken() {
      this.isLoading = true;
      try {
        const { id } = await api.post('tokens', {
          name: this.name,
          description: this.description,
          collection: this.collection,
          tags: this.tags,
          price: 1,
        });

        let formData = new FormData();
        formData.append('asset', this.file);

        await api.post(`tokens/${id}/upload`, formData);
        this.onClose();
        this.redirectToDetails(id);
      } catch (e) {
        console.error(e);
        this.errorMessage = "Couldn't create NFT. Try again later.";
      }
      this.isLoading = false;
    },

    onClose() {
      this.$emit('close');
    },

    redirectToDetails(tokenId) {
      redirect({
        name: 'TokenDetails',
        params: {
          id: tokenId,
        },
      });
    },
  },
};
</script>
